.intro{
  margin-top: 6rem;
  /* height: 77vh; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10rem;
}
.intro-left{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.intro-left>div{
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
}
.intro-left>div>:nth-child(1){
  font-size: 3rem;
  color: var(--black);
  font-weight: bold;
}
.intro-left>div>:nth-child(2){
  font-size: 3rem;
  color: var(--orange);
  font-weight: bold;
}
.intro-left>div>:nth-child(3){
font-size: 14px;
font-weight: 100;
color: var(--gray);
}
.i-btn{
  width: 6rem;
  height: 2rem;
}
.i-pics{
  margin-top: 1.8rem;
  display: flex !important;
  gap: 2rem !important;
}
.i-pics>a>img{
  height: 2rem;
  width: 2rem;
  /* transform: scale(0.5); */
}
.i-pics>img{
  cursor: pointer;
}




.intro-right{
position: relative;
}
.intro-right>*{
  position: absolute;
  z-index: 1;
}
.intro-right>:nth-child(1){
  transform: scale(0.69);
  left: -15%;
  top: -9%;
}
.intro-right>:nth-child(2){
  transform: scale(0.67);
  left: -3rem;
  top: -4.6rem;
}
.intro-right>:nth-child(3){
  transform: scale(1.4);
  left: 28%;
}
.intro-right>:nth-child(4){
  transform: scale(0.6);
  top: -19%;
  left: -24%;
  border-radius: 50%;
}
.blur{
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(72px);
  z-index: -9;
}

@media (max-width: 28em) {
  .intro{
    grid-template-columns: 1fr;
  }
  .intro-right{
    margin-top: 5rem;
  }
  .intro-left{
    /* text-align: center; */
    width: 20rem;
  }
  .c-blur-1 {
    top: 3rem;
  }
  .blur {
    position: absolute;
    width: 18rem;
    height: 15rem;
  }
  .intro-right>:nth-child(3) {
    transform: scale(1.2);
    left: 16%;
    top: -1.8rem;
}
.intro-right>:nth-child(2) {
  transform: scale(0.45);
  left: -8rem;
  top: -4.6rem;
}
.intro-right>:nth-child(1) {
  transform: scale(0.53);
  left: -33%;
}
.intro-right>:nth-child(4) {
  margin-top: -8%;
}
.c-blur-1 {
  top: 1rem !important;
  left: 3rem !important;
}
/* .i-pics{
  justify-content: center;
} */
a{
  margin-left: 0 !important;
}
}

@media (max-width: 23.43em) {

  .intro-left>div{
    width: 17rem;
  }
  .c-blur-1 {
    left: 0rem !important;
}
.intro-right>:nth-child(4) {
  margin-top: -19%;
}
.intro-right>:nth-child(2) {
  transform: scale(0.4);
  left: -10rem;
}
.intro-right>:nth-child(1) {
  transform: scale(0.45);
  left: -44%;
}
.intro-right>:nth-child(3) {
  transform: scale(1.2);
  left: 6%;
  top: -2.8rem;
}
}

@media (max-width: 20.62em) {
  .c-blur-1 {
    left: -2rem !important;
}
.intro-left>div>:nth-child(1) {
  font-size: 2.3rem;
}
.intro-left>div>:nth-child(2) {
font-size: 2.3rem;
}
.intro-left>div>:nth-child(3) {
  width: 15rem;
}
.intro-left {

  width: 17rem;
}
}
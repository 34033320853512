.experience{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 8rem;
  margin-top: 32rem;
}
.achivement{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.circle{
  height: 4rem;
  width: 4rem;
  background: white;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.circle::before{
  content: "";
  border-radius: 100%;
  background-image: linear-gradient(to bottom, #87e6fb 0%, #ffc05c 100%);
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  position: absolute;
  z-index: -1;
  box-shadow: var(--boxShadow);
}
.achivement>:nth-child(3){
  color: var(--orange);
  margin-top: 0.2rem;
}

@media (max-width: 23.43em) {
  .experience {
    gap: 2rem !important;
  }
}

@media (max-width: 20.62em) {
  .experience {
    gap: 1.5rem !important;
  }
}
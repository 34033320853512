.testmonial{
  margin-top: 8rem;
  padding: 0 3rem;
  position: relative;
  margin-bottom: 5rem;
}
.testmonial-color{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.testmonial-color>*{
  font-size: 2rem;
  font-weight: bold;
}
.testmonial-color>:nth-child(2){
  color: var(--orange);
}
.test{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 30rem;
  background: rgba(255, 255, 255, 0.26);
  border: 5px solid var(--blueCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
}
.test>img{
  width: 6rem;
  height: 6.3rem;
  border-radius: 100%;
}
.test>span{
  color: var(--gray);
  margin-top: 1rem;
}
.testmonial .swiper{
  width: 100%;
  height: 70%;
}
.testmonial .swiper-slide{
display: flex;
align-items: center;
justify-content: center;
}
.testmonial .swiper-pagination{
position: initial;
margin-top: 1.6rem;
}
.testmonial .swiper-pagination-bullet-active{
    background: var(--orange) !important; 
}

@media (max-width: 28em) {
  .testmonial {
    margin-top: 4rem;
    padding: 0rem;
  }
  .testmonial-color{
    flex-direction: column;
    text-align: center;
  }
}
.services{
  display: flex;
  padding: 0 3rem;
  margin-bottom: 25rem;
  margin-top: 13rem;
}
.services-left{
  display: flex;
  flex-direction: column;
  position: relative;
  /* flex: 1; */
}
.services-left>:nth-child(1){
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.services-left>:nth-child(2){
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.services-left>:nth-child(3){
  color: var(--gray);
  font-size: 14px;
  margin-top: 1rem;
}
.services-left>:nth-child(4){
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
}

.s-blur{
  top: 13rem;
  left: -18rem;
}
.s-blur-2{
left: 14rem;
top: 8rem;
}






.services-right{
  display: flex;
  flex-direction: column;
  position: relative;
  /* flex: 1; */
}
.services-right>*{
  position: absolute;
}


@media (max-width: 28em) {
  .services{
    margin-top: 24rem;
    margin-bottom: 56rem;
    flex-direction: column;
    padding: 0;
  }
  /* .services-left{
    text-align: center;
  } */
  .checkk{
    left: 4rem !important;
    top: 2rem !important; 
  }
  .checkk2{
    top: 19rem !important;
    left: 4rem !important;
  }
  .checkk3{
    top: 36rem !important;
    left: 4rem !important;
  }
  .float{
    display: none;
  }

}

@media (max-width: 23.43em) {
  .checkk {
    left: 2rem !important;
  }
  .checkk2{
    left: 2rem !important;
  }
  .checkk3{
    left: 2rem !important;
  }
}

@media (max-width: 20.62em) {
  .checkk {
    left: 1rem !important;
}
.checkk2{
  left: 1rem !important;
}
.checkk3{
  left: 1rem !important;
}
}
.nav{
  display: flex;
  justify-content: space-between;
  height: 10vh;
}
.n-left{
  display: flex;
  gap: 2rem;
  align-items: center;
}
.logo{
  font-size: 1.3rem;
  font-weight: bold;
}
.n-right{
  display: flex;
  gap: 4rem;
  align-items: center;
}
.n-lists{
  display: flex;
  text-decoration: none;
  gap: 2rem;
}
.n-lists > li{
  display: flex;
  align-items: center;
}
.n-lists > li:hover{
  cursor: pointer;
  color: var(--orange);
}
.n-btn{
  flex: 2;
}
li{
  list-style: none;
  cursor: pointer;
}



@media (max-width: 28em) {
  .n-lists{
    display: none;
  }
  .App {
    padding: 0.5rem 1rem;
  }
  .n-left{
    gap: 9rem;
  }
  .n-btn{
    display: none !important;
  }
}
@media (max-width: 23.43em) {
  .logo {
    width: 8rem;
  }
  .n-left {
    gap: 6rem;
}
}

@media (max-width: 20.62em) {
  .n-left {
    gap: 4rem;
}
}
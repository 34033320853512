.work{
  padding: 0 3rem;
  display: flex;
  margin-bottom: 10rem;
}
.leftii{
  margin-top: 1rem;
}
.work-right{
  position: relative;
  margin-top: 3.5rem;
}
.main-circle{
  position: relative;
  background: white;
  height: 18rem;
  width: 18rem;
  border-radius: 100%;
  box-shadow: var(--smboxShadow);

}
.main-circle{
  position: absolute;
  /* top: 0; */
  left: 7rem;
}
.main-circle>:nth-child(1){
  top: -3rem;
  left: 5.7rem;
}
.main-circle>:nth-child(2){
  top: 5rem;
  left: -3rem;
}
.main-circle>:nth-child(3){
  top: 6rem;
  left: 6rem;
}
.main-circle>:nth-child(4){
  top: 5rem;
  left: 15rem;
}
.main-circle>:nth-child(5){
  top: 14rem;
  left: 6rem;
}
.second-circle{
  position: absolute;
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: var(--smboxShadow);
}
.second-circle>img{
  transform: scale(0.6);
}
.back-circle{
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
  z-index: -1;
}
.blue-circle{
  background: #1949b8;
  top: 0 ;
  left: 18rem;
}
.yellow-circle{
  background: #F5C32C;
  left: 18rem;
  top: 8rem;
}

@media (max-width: 28em) {
  .experience{
    gap: 3rem;
    margin-bottom: 3rem;
  }
  .work{
    flex-direction: column;
  }
  .work-right{
    margin-left: -8rem;
    margin-top: 5.5rem;
  }
  .main-circle {
    position: absolute;
    /* top: 0; */
    left: 9.6rem;
    height: 11rem;
    width: 11rem;
}
.second-circle {
  height: 4rem;
  width: 4rem;
}
.second-circle>img {
  transform: scale(0.4);
}
.blue-circle {
  top: 0;
  left: 14rem;
}
.back-circle {

  height: 9rem;
  width: 9rem;
}
.yellow-circle {
  left: 15rem;
  top: 3rem;
}
.main-circle>:nth-child(1) {
  top: -3rem;
  left: 3.7rem;
}
.main-circle>:nth-child(2) {
  top: 4rem;

  left: -3rem;
}
.main-circle>:nth-child(5) {
  top: 10rem;
  left: 3.7rem;
}
.main-circle>:nth-child(4) {
  top: 4rem;
  left: 10rem;
}
.main-circle>:nth-child(3) {
  top: 3.5rem;
  left: 3.5rem;
}

}

@media (max-width: 23.43em) {
.work{
  padding: 0;
}
.work-right {
  margin-left: -7rem;
}
}

@media (max-width: 20.62em) {
  .work-right {
    margin-left: -8rem !important;
}
  .main-circle>:nth-child(2) {
    left: -2rem;
}
.main-circle>:nth-child(4) {
  left: 9rem;
}
.blue-circle {
  top: 0;
  left: 13rem;
}
.yellow-circle {
  left: 14rem;
  top: 2rem;
}
}
.footer{
  position: relative;
}
.footer>img{
  width: 100%;
  position: relative;
}
.icons{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 13rem;
  left: 22.5rem;
  gap: 1rem;

}
.icons>a>*{
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}
.icons>*:hover{
  transform: scale(1.2);
}
.icons>:nth-child(1){
  color: 	#4267B2;
}
.icons>:nth-child(2){
  color: 	#171515;
}
.icons>:nth-child(3){
  color: 	 #E1306C;
}


@media (max-width: 28em) {
  .icons>a>* {
    height: 2rem;
    width: 2rem;
    gap: 0rem;
}
.icons{
  top: 4rem;
  left: 1rem;
}
}
@media (max-width: 23.43em) {
  .icons {
    top: 3.3rem;
    left: 4rem;
}
.icons{
  top: 3.5rem;
  left: 3.5rem;
}
}

@media (max-width: 20.62em) {
  .icons {
    top: 2.7rem;
    left: 3.5rem;
}
}
.ContactUs{
  padding: 0 3rem;
  display: flex;
  margin-top: 4rem;
}
.contact-right{
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative;
}
.contact-right>form{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.contact-right .user{
  width: 20rem;
  height: 2rem;
  padding: 0.3rem;
  outline: none;
  border: 2px solid var(--orange);
  border-radius: 8px;
  font-size: 16px;
}
textarea{
  height: 4rem !important;
}
.c-blur-1{
  top: 1rem;
  left: 8rem;
}


@media (max-width: 28em) {
  .ContactUs{
    flex-direction: column;
    padding: 0;
  }
}
@media (max-width: 23.43em) {
  .contact-right .user {
    width: 16rem;
  }
}

@media (max-width: 20.62em) {
  .contact-right .user {
    width: 15rem;
}
}